import React from "react"
import "./notFound.scss"


const NotFound = ()=>{
    return(
        <div class="error">
      <div class="cosmonot"/>
      <div class="error-box">
        <div class="error-box-title ex-bold">PAGE NOT FOUND</div>
        <p class="error-box-desc">
          The page you're trying to direct <br />
          has disappeared or doesn't exist.
        </p>
        <div class="error-box-footer">
          <a href="https://ingabonimanzidesign.com" class="error-box-link"> Back to Home page </a>
          <div class="world-border"/>
        </div>
      </div>

      <div id="stars" />
      <div id="star2" />
      <div id="star3" />
    </div>
    )
}

export default NotFound