import React from "react";
import { dsnCN } from "../../../hooks/helper";
import {
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function MenuContent({ className }) {
  const socialData = [
    {
      link: "https://www.instagram.com/ingabonimanzistudio/?hl=en",
      icon: faInstagram,
    },
    { link: "https://twitter.com/pacifiqngabo", icon: faTwitter },
    {
      link: "https://www.youtube.com/channel/UCBMwGLkI_sYQZjQh1luyvvA/videos",
      icon: faYoutube,
    },
  ];
  return (
    <div
      className={dsnCN(
        "container-content  d-flex flex-column justify-content-center",
        className
      )}
    >
      <div className="nav__info">
        <div className="nav-content">
          <h4 className="sm-title-block mb-10">Address</h4>
          <p>
            <span>
              Kicukiro, Kigali, Rwanda
              <br />
              37, kk 3 Ave
            </span>
          </p>
        </div>
        <div className="nav-content mt-30">
          <h4 className="sm-title-block mb-10">Contact</h4>
          <h6>
            <p className="links over-hidden mb-1">
              <a
                className="link-hover"
                href="tel:+250 784 828 724"
                data-hover-text="+250 784 828 724"
              >
                +250 784 828 724
              </a>
            </p>
          </h6>
          <p className="links over-hidden">
            <a
              className="link-hover"
              href="info@ingabonimanzidesign.com"
              data-hover-text="info@ingabonimanzidesign.com"
            >
              info@ingabonimanzidesign.com
            </a>
          </p>
        </div>
      </div>
      <div className="nav-social nav-content mt-30">
        <div className="nav-social-inner p-relative">
          <h4 className="sm-title-block mb-10">Follow us</h4>
          <ul>
            {socialData.map((item, index) => (
              <li key={index}>
                <h4>
                  <a
                    href={item.link}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={item.icon} />
                  </a>
                </h4>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default MenuContent;
